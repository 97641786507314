import "@babel/polyfill";
import './find.js';
import 'react-app-polyfill/ie11';
import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.scss';
// eslint-disable-next-line
import lazysizes from "lazysizes";
import Nav from "components/Nav";
import Sections from "components/Sections";
import ReactTooltip from 'react-tooltip'
import DownloadShare from "components/DownloadShare";
import FullReport from 'downloads/PGPF TEN YEAR REPORT.pdf';
import FadeIn from "components/FadeIn.js";
import { goToAnchor } from "react-scrollable-anchor";

const App = () => {
  const [menuOpen,setMenuOpen] = useState(false);
  const [isMobile,setIsMobile] = useState();

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('overflow--hidden')
    } else {
      document.body.classList.remove('overflow--hidden')
    }
  }, [menuOpen])

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize()
    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, [])

  function onResize() {
    setIsMobile(window.innerWidth < 800)
  }

  return (
    <div className={`App font--body`}>
      <Nav menuOpen={menuOpen} setMenuOpen={setMenuOpen} isMobile={isMobile} />
      <FadeIn>
        <header className="Hero">
          {!isMobile && <div className="Hero-flex-spacer" />}
          <h1>
            <div className="font--heroh2">Peter G. Peterson Foundation</div>
            <div className="font--heroh1">Ten Year Report</div>
          </h1>
          <img alt="" className="Hero-mark" src={logo} />
          {!isMobile && (
            <a className="Hero-link"
              href={FullReport}
              target="_blank"
              rel="noopener noreferrer">
              View Report
            </a>
          )}
          {!isMobile && <div className="Hero-down" onClick={() => {
            goToAnchor('letter')
          }} />}
        </header>
      </FadeIn>
      <Sections />
      {!isMobile && <DownloadShare />}
      <footer className="Footer">© 2019 PETER G. PETERSON FOUNDATION. ALL RIGHTS RESERVED.</footer>
      <ReactTooltip type="light" effect="solid" place="left" offset={{ top: 10 }} />
    </div>
  );
}

export default App;
