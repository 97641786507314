import React from "react";
import FadeIn from "components/FadeIn.js";
export default ({ src, alt, className="", ...rest }) => {
  return (
    <FadeIn className={`Image ${className}`}>
      <img
        className={`lazyload ${className}`}
        data-sizes="auto"
        data-src={src}
        alt={alt}
        {...rest} />
    </FadeIn>
  )
}