import React from "react";

import Letter from "content/letter.js";
import Founder from "content/founder.js";
import Fiscal from "content/fiscal.js";
import About from "content/about.js";
import Work from "content/work.js";

export default {
  sections: [
      {
        navtitle: "Letter from the Chairman & CEO",
        heading: "Letter",
        anchor: "letter",
        subheading: "From the chairman & CEO",
        Content: Letter
      },
      {
        navtitle: "Our Founder",
        heading: "Our Founder",
        anchor: "founder",
        subheading: <><div>Peter G. Peterson</div><div>1926 – 2018</div></>,
        Content: Founder
      },
      {
        navtitle: "State of Fiscal",
        heading: "State of Fiscal",
        anchor: "fiscal",
        subheading: null,
        Content: Fiscal
      },
      {
        navtitle: "Our Work",
        heading: "Our Work",
        anchor: "work",
        Content: Work
      },
      {
        navtitle: "About Us",
        heading: "About Us",
        anchor: "about",
        Content: About
      },
      {
        navtitle: "pgpf.org",
        externalLink: "https://pgpf.org"
      }
  ]
}