import React, { useRef } from "react";
import SlideToggle from "react-slide-toggle";

export default ({ children }) => {
  const buttonRef = useRef()
  return (
    <SlideToggle
      collapsed
      bestPerformance
      onCollapsed={() => {
        window.scrollTo(window.scrollX,buttonRef.current.offsetTop - window.innerHeight/2)
      }}
      render={({onToggle, setCollapsibleElement, toggleState}) => {
        return (
          <div
            ref={buttonRef}
            className={`ReadMore ${toggleState !== "COLLAPSED" && "SlideToggle--expanded"}`}>
            <div
              style={{ opacity: toggleState === "EXPANDED" ? 1 : 0 }}
              className="my-collapsible__content"
              ref={setCollapsibleElement}>
              <div className="my-collapsible__content-inner">
                {children}
              </div>
            </div>
            <button className="ReadMore-button SlideToggle-button" onClick={onToggle}>
              {toggleState === "COLLAPSED" ? "Read More" : "Collapse"}
            </button>
          </div>
        )
      }}
    />
  )
}