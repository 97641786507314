import React, { useState, useEffect } from "react";
import Pdf from "pdf.js";
import Link from "link.js";

import FullReport from "downloads/PGPF TEN YEAR REPORT.pdf";

export default () => {
  useEffect(() => {
    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  })

  const [scrolled,setScrolled] = useState(false)

  function onScroll() {
    const isScrolled = window.pageYOffset > 75;
    if (scrolled !== isScrolled) {
      setScrolled(isScrolled)
    }
  }
  const fill = scrolled ? "#000" : "#fff";
  return (
    <div className="DownloadShare">
      <a className="DownloadShare-item"
        href={FullReport}
        target="_blank"
        download
        rel="noopener noreferrer">
        <div className="tooltip-inner"><span className="font--buttonsmall">Download Report</span></div>
        <Pdf fill={fill} />
      </a>
      <CopyLink fill={fill} />
    </div>
  )
}

const CopyLink = ({ fill }) => {
  const [copied,setCopied] = useState(false)
  function onLinkClick() {
    copyTextToClipboard(window.location.href).then((successful) => {
      setCopied(successful)
    })
  }

  function onMouseLeave() {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 500)
    }
  }

  return (
    <button
      type="button"
      className="DownloadShare-item reset-button"
      onClick={onLinkClick}
      onMouseLeave={onMouseLeave}>
      <div className="tooltip-inner">
        <span className="font--buttonsmall">{copied ? "Link Copied!" : "Copy Link"}</span>
      </div>
      <Link fill={fill} />
    </button>
  )
}


function fallbackCopyTextToClipboard(text) {
  const scrollPos = [window.pageXOffset,window.pageYOffset]; //ie11 fix
  var textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  window.scrollTo(...scrollPos) //ie11 fix ^

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
    document.body.removeChild(textArea);
    return successful;
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
    document.body.removeChild(textArea);
    return false
  }
}
function copyTextToClipboard(text) {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      resolve(fallbackCopyTextToClipboard(text));
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
      resolve(true)
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
      resolve(false)
    });
  })

}