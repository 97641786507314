import React from "react";
import ReadMore from 'components/ReadMore.js';
import ExpandableStatement from 'components/ExpandableStatement.js';
import Image from 'components/Image.js';
import Downloads from 'components/Downloads.js';
import ViewSection from 'components/ViewSection.js';
import StateOfFiscal from 'downloads/STATE OF FISCAL.pdf';
import FiscalDrivers from 'downloads/STATE OF FISCAL – KEY DRIVERS.pdf';
import WhyDebtMatters from 'downloads/STATE OF FISCAL – WHY DEBT MATTERS.pdf';
import PathForward from 'downloads/STATE OF FISCAL – PATH FORWARD.pdf';
import FadeIn from "components/FadeIn.js";

import FederalDebt from "images/Federal_Debt.png"
import HealthcareSpending from "images/Healthcare_Spending.png";

export default () => (
  <>
    <FadeIn>
      <p>The United States is on an unsustainable and dangerous fiscal path. Our nation’s debt is at historically high levels and projected to rise dramatically, posing a significant risk to our economy and to every American’s future.</p>
      <p>Our debt challenge isn’t based on politics or partisanship – it’s the simple math of spending more than we take in. America has 73 million baby boomers retiring and living longer, a highly inefficient healthcare system, and a tax code that fails to generate sufficient revenues. These factors combine to pose real threats to our future, from lower productivity and reduced public and private investment to a higher likelihood of fiscal crises, weakening of our safety net, and diminishing economic opportunities for Americans.</p>
    </FadeIn>
    <ReadMore>
      <p>The vast majority of American voters recognize these dangers and believe that addressing our nation’s debt should be a priority in Washington. Most elected officials say that they agree. Yet the problem remains unaddressed.</p>
      <p>The good news is that viable solutions exist that can reverse our fiscal course and reduce the harm of our current trajectory. But the longer we wait to take action, the more difficult and painful it will be to tackle the problem. Now is the time to chart a better path and create the conditions for sustained economic growth and prosperity into the future.</p>
    </ReadMore>
    <ExpandableStatement
      heading={<>Our Federal debt is on an <span className="highlight-text">unsustainable</span> path.</>}
      body={
        <>
          <div className="ExpandableStatement-indent">
            <p>In 2018, gross federal debt exceeded $21 trillion, by far an all-time high. Budget deficits will soon exceed $1 trillion per year, and over the next decade we are on pace to add $12.4 trillion to the national debt. As a share of gross domestic product (GDP), debt held by the public has already reached its highest level since 1950, and it is on pace to nearly equal the size of our entire economy by 2028. Deficits and debt are projected to increase rapidly in the future, with no end in sight.</p>
          </div>
          <div className="image-label font--label">Debt Held by the Public (% of GDP)</div>
          <Image className="Image--withfootnote" src={FederalDebt} alt="US Federal Debt Projections" />
          <div className="Image-footnote font--footnote">SOURCE: Congressional Budget Office, <em>The 2018 Long-Term Budget Outlook</em>, June 2018. Compiled by PGPF.</div>
        </>
      }/>
    <ExpandableStatement
      heading={<>The growing debt is caused by a structural mismatch between <span className="highlight-text">spending</span> and <span className="highlight-text">revenues</span>.</>}
      body={
        <>
          <div className="ExpandableStatement-indent">
            <div className="font--h2">The Structural Deficit</div>
            <p>Throughout American history, national emergencies including wars and economic downturns have had significant short-term impacts on our fiscal condition. Events like the Great Depression, World War II and the 2008 financial crisis, for example, all caused deficits to rise temporarily.</p>
            <p>Today’s fiscal situation is different. Debt and deficits are rising, but not in response to an emergency or a temporary economic downturn. Instead, our fiscal challenges stem from a built-in, structural mismatch between spending and revenues.</p>
            <p>This mismatch is caused by a mix of predictable drivers, including demographic trends and high healthcare costs combined with an inadequate revenue base for the spending policies that we have enacted, all of which is exacerbated by compounding debt and interest costs. Adding to the problem, in 2017 and 2018, lawmakers passed fiscally irresponsible tax and spending measures that made our nation’s fiscal outlook considerably worse – adding more than $2 trillion in new debt over the next 10 years.</p>
          </div>
          <div className="image-label font--label">Federal Revenues and Spending (% of GDP)</div>
          <Image className="Image--withfootnote" src="/assets/Federal Deficit.png" alt="US Federal Deficit" />
          <div className="Image-footnote font--footnote">SOURCE: Congressional Budget Office, <em>The 2018 Long-Term Budget Outlook</em>, June 2018, and <em>The Budget and Economic Outlook: 2018 to 2028</em>, April 2018; and PGPF calculations based on CBO data. Compiled by PGPF.</div>
        </>
      }/>
    <ExpandableStatement
      heading={<><span className="highlight-text">Healthcare is the major driver</span> of the growth in federal spending over the long term.</>}
      body={
        <>
          <div className="image-label font--label">Federal Spending (% of GDP)</div>
          <Image className="Image--withfootnote" src={HealthcareSpending} alt="Healthcare Spending" />
          <div className="Image-footnote font--footnote">SOURCE: Congressional Budget Office, <em>The 2018 Long-Term Budget Outlook</em>, June 2018, and <em>The Budget and Economic Outlook: 2018 to 2028</em>, April 2018; and PGPF calculations based on CBO data. Compiled by PGPF.</div>
        </>
      }/>
    <ExpandableStatement
      heading={<>By 2048, <span className="highlight-text">interest costs are projected to be more than twice</span> what the federal government has historically spent on R&D, infrastructure and education, combined.</>}
      body={
        <>
          <div className="image-label font--label">Federal Spending (% of GDP)</div>
          <Image className="Image--withfootnote" src="/assets/Federal Interest.png" alt="US Federal Interest Spending" />
          <div className="Image-footnote font--footnote">SOURCE: Office of Management and Budget, <em>Budget of the United States Government, Fiscal Year 2019</em>, February 2018; Congressional Budget Office, <em>The 2018 Long-Term Budget Outlook</em>, June 2018. Compiled by PGPF. NOTE: Infrastructure excludes defense.</div>
          <div className="image-label font--label">Federal Interest Costs (Billions)</div>
          <Image className="Image--withfootnote" src="/assets/Federal Interest 10 year.png" alt="US Federal Interest Spending 10 year" />
          <div className="Image-footnote font--footnote">SOURCE: Congressional Budget Office, <em>An Analysis of the President’s 2019 Budget</em>, May 2018, and <em>The Budget and Economic Outlook: 2018 to 2028</em>, April 2018. Compiled by PGPF.</div>
        </>
      }/>
    <Downloads
      items={[
        {
          heading: "Key Drivers of Debt",
          download: FiscalDrivers
        },
        {
          heading: "Why Debt Matters",
          download: WhyDebtMatters
        },
        {
          heading: "The Path Forward",
          download: PathForward
        }
      ]} />
    <ViewSection download={StateOfFiscal} />
  </>
)