import React from "react";
import data from "data.js";
import ScrollableAnchor from "react-scrollable-anchor";
import FadeIn from "components/FadeIn.js";

export default () => {
  return (
    data.sections.filter(d=>d.externalLink===undefined).map(section => {
      return <Section key={section.navtitle} {...section} />
    })
  )
}

const Section = ({
  heading,
  subheading,
  anchor,
  Content
}) => {
  return (
    <ScrollableAnchor id={anchor}>
      <section className="Section">
        <div className="Section-inner">
          <FadeIn>
            <h2 className="Section-header">
              <div className="Section-heading font--h1">{heading}</div>
              <div className="Section-subheading font--h2">{subheading}</div>
            </h2>
          </FadeIn>
          <div>{Content && <Content />}</div>
        </div>
      </section>
    </ScrollableAnchor>
  )
}