import React, { useState, useEffect } from "react";
import data from "../data.js";
import wordmark from "PGPFlogo.svg";
import mark from "mark.svg";
import { useTransition, useSpring, animated } from "react-spring";
import { configureAnchors, goToTop } from 'react-scrollable-anchor';
import FullReport from "downloads/PGPF TEN YEAR REPORT.pdf";

const NAVHEIGHT_MOBILE = 48;
const NAVHEIGHT_DESKTOP = 66;

configureAnchors({
  offset: -NAVHEIGHT_MOBILE
})
export default ({ menuOpen, setMenuOpen, isMobile}) => {
  const [scrolled,setScrolled] = useState();
  const [currentHash,setCurrentHash] = useState();
  let hashTimer;

  const transitions = useTransition(scrolled, null, {
    from: {
      transform: "translateY(100px)",
    },
    enter: {
      transform: "translateY(0%)",
    },
    leave: {
      transform: "translateY(-100px)",
    },
    unique: true
  })
  const [navHeight,setNavHeight] = useSpring(() => ({
    height: "66px",
    config: {
      duration: 300
    }
  }))

  const currentNavHeight = scrolled ? NAVHEIGHT_MOBILE : NAVHEIGHT_DESKTOP;
  setNavHeight({
    height: currentNavHeight + 'px'
  });

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    onScroll()
    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  }, [])

  function onScroll() {
    const isScrolled = window.pageYOffset > window.innerHeight - currentNavHeight;
    setScrolled(isScrolled)

    clearTimeout(hashTimer)
    hashTimer = setTimeout(() => {
      setCurrentHash(window.location.hash)
    }, 100) //kinda hacky but hash event doesnt fire when hash is removed
  }


  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  return (
    <animated.nav
      className={`
        Nav
        ${isMobile ? 'Nav--mobile' : 'Nav--desktop'}
        ${scrolled ? 'Nav--compact' : 'Nav--full'}
      `}>
      <animated.div className="Nav-inner font--button" style={navHeight}>
      {transitions.map(({ item, key, props}) =>
        item
          ? <animated.div key={key} className="Nav-home Nav-home--scrolled" style={props}>
            <a
              className="Nav-home-link"
              href="/"
              onClick={(e) => {
                e.preventDefault()
                goToTop()
              }}>
              <img alt="Ten Year Report" src={mark} />
              <div>Ten Year Report</div>
            </a>
          </animated.div>
          : <animated.div key={key} className="Nav-home Nav-home--top" style={props}>
            <a href="https://www.pgpf.org/"
              target="_blank"
              rel="noopener noreferrer">
              <img alt="Peter G. Peterson Foundation" src={wordmark} />
            </a>
          </animated.div>
      )}
      {isMobile
        ? (<>
            <button
              className={`Nav-menutoggle ${menuOpen ? 'Nav-menutoggle--close' : ''}`}
              onClick={toggleMenu}
              aria-label={menuOpen ? 'Open Menu' : 'Close Menu'}>
              <div />
              <div />
              <div />
            </button>
            {menuOpen && (<div
              className="Nav-links Nav-links--mobile"
              style={{
                height: `calc(100vh - ${currentNavHeight}px)`,
                top: currentNavHeight
              }}>
              {data.sections.map(item => {
                const hash = `#${item.anchor}`;
                return (item.externalLink ?
                  <a key={item.navtitle} href={item.externalLink} target="_blank" rel="noopener noreferrer">{item.navtitle}</a> :
                  <a className={hash === currentHash ? 'active' : ''}
                    key={item.heading}
                    href={hash}
                    onClick={() => {
                      setMenuOpen(false)
                    }}>
                    {item.navtitle}
                  </a>
                )
              })}
            </div>)}
          </>
        )
        : <div className="Nav-links Nav-links--desktop">
          {data.sections.map(item => {
            const hash = `#${item.anchor}`;
            return (item.externalLink ?
              <a key={item.navtitle} href={item.externalLink} target="_blank" rel="noopener noreferrer">
                <div className="Nav-link-inner">{item.navtitle}
              </div></a> :
              <a className={hash === currentHash ? 'active' : ''} key={item.navtitle} href={`#${item.anchor}`}>
                <div className="Nav-link-inner">{item.heading}</div>
              </a>
            )
          })}
        </div>
      }
      </animated.div>
      {isMobile && <animated.a href={FullReport} className="Nav-report-link reset-link" style={{ height: NAVHEIGHT_MOBILE }}>View Full Report</animated.a>}
    </animated.nav>
  )
}