import React from "react";
import SlideToggle from "react-slide-toggle";
import FadeIn from "components/FadeIn.js";

export default ({ heading, body }) => {

  return (
    <FadeIn><SlideToggle
      collapsed
      bestPerformance
      render={({onToggle, setCollapsibleElement, toggleState}) => {
        return (
          <div className={`ExpandableStatement ${toggleState !== "COLLAPSED" && "SlideToggle--expanded"}`}>
            <button className="ExpandableStatement-button SlideToggle-button" onClick={onToggle}>{heading}</button>
            <div
              style={{ opacity: toggleState === "EXPANDED" ? 1 : 0 }}
              className="my-collapsible__content"
              ref={setCollapsibleElement}>
              <div className="ExpandableStatement-inner">{body}</div>
            </div>
          </div>
        )
      }}
    /></FadeIn>
  )
}