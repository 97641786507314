import React from "react";
import Image from "components/Image";
import Downloads from "components/Downloads";
import FadeIn from "components/FadeIn.js";

import Grantees from "downloads/GRANTEES.pdf";
import Leadership from "downloads/LEADERSHIP.pdf";

export default () => (
  <>
    <Image src={"/assets/Peterson Team.png"} alt="Peterson Staff" />
    <FadeIn>
      <p>The Peter G. Peterson Foundation is a non-profit, non-partisan organization that is dedicated to increasing public awareness of the nature and urgency of key fiscal challenges threatening America's future, and to accelerating action on them. To address these challenges successfully, we work to bring Americans together to find and implement sensible, long-term solutions that transcend age, party lines and ideological divides in order to achieve real results.</p>
    </FadeIn>
    <div className="Section-line" />
    <div className="Subsection">
      <FadeIn>
        <div className="font--h2 text-align--center">Financials</div>
        <div>
          <p>In 2008, Pete Peterson established the Foundation with a $1 billion commitment to help make the American Dream available to every citizen. Since its inception, Foundation program expenditures have focused on three primary areas: grants and grantmaking; education, engagement and awareness; and policy research and analysis. The Foundation has consistently worked alongside trusted partners, as well as through our own initiatives and research, to raise awareness of our nation’s fiscal challenges, spur action and advance solutions. </p>
          <p>In 2014, the Foundation established the Peterson Center on Healthcare with an initial $200 million commitment, with the mission to make higher-quality, more affordable healthcare a reality for all Americans.</p>
          <p>As of March 31, 2018, the Foundation’s unrestricted assets totaled $873 million. From 2008 to 2018, the Foundation’s total expenditures were $253.9 million, including $219.6 million in program expenditures and $34.3 million in supporting services.¹</p>
        </div>
      </FadeIn>
      <FadeIn>
      <div className="Columns">
        <div className="Columns-item Columns-item--financial">
          <div className="font--h3 text-align--center">Total Program Expenditures: $219,572,000</div>
          <Image src="/assets/Program Expenditures.svg" alt="Program Expenditures" />
          <div className="font--footnote">
            <em>¹ The Foundation’s fiscal year ends March 31. Annual financial statements for FY 2016 – 2018 were audited by KPMG. Annual financial statements for FY 2009 – 2015 were audited by Mitchell & Titus, LLP, a member firm of Ernst & Young Global Limited. Supporting services includes human resources, legal, accounting and other administrative expenses.</em>
          </div>
        </div>
        <div className="Columns-item Columns-item--financial">
          <div className="font--h3 text-align--center">Grants by Activity Type</div>
          <Image src="/assets/Grants by Activity Type.svg" alt="Grants by Activity Type" />
        </div>
      </div>
      </FadeIn>
    </div>

    <Downloads
      items={[
        {
          heading: "Leadership",
          download: Leadership
        },
        {
          heading: "Grantees",
          download: Grantees
        }
      ]} />
  </>
)