import React from "react";
import { Waypoint } from "react-waypoint";
import { useSpring, animated } from "react-spring"

export default ({
  children,
  className
}) => {
  const [props,setProps] = useSpring(() => ({
    from: {
      opacity: 0
    },
    config: {
      friction: 70
    }
  }))
  function onEnter() {
    setProps({
      opacity: 1
    })
  }
  function onLeave() {
    setProps({
      opacity: 0
    })
  }

  return (
    <Waypoint onEnter={onEnter} onLeave={onLeave}>
      <div>
        <animated.div className={className} style={props}>
          {children}
        </animated.div>
      </div>
    </Waypoint>
  )
}
