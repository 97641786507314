import React from "react";
import FadeIn from "components/FadeIn.js";

export default ({ items }) => {
  return (
    <FadeIn>
      <ul className="Downloads">
        {items.map(item => <Item key={item.heading} {...item} />)}
      </ul>
    </FadeIn>
  )
}

const Item = ({ heading, download }) => {
  return (
    <li className="Download">
      <a href={download} className="reset-link" target="_blank" rel="noopener noreferrer">
        <div className="font--download">{heading}</div>
        <div className="Download-link button reset-link">View</div>
      </a>
    </li>
  )
}