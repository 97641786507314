import React from "react";

import Image from "components/Image.js";
import ReadMore from "components/ReadMore.js";
import Michael from "images/Michael Peterson.jpg";
import Signature from "images/MP Signature.png";
import FadeIn from "components/FadeIn.js";

export default () => (
  <>
    <Image className="contain--med center-margin" src={Michael} alt="Michael Peterson" /> {/*CEO*/}
    <div>
      <FadeIn>
        <p>In 2008, the Peter G. Peterson Foundation was established with the goal of preserving the American Dream for the next generation. My father saw our high and rising debt as a transcendent threat to our future economy &ndash; posing a risk to every American, and our leadership role in the world. </p>
        <p>As we look back on the first decade of our work, our impact and reach have grown, but the challenge remains. And our commitment has never been stronger.</p>
      </FadeIn>

      <ReadMore>
        <p>The United States&rsquo; fiscal challenge is the most predictable crisis in history. That&rsquo;s because it is driven by entirely foreseeable trends: a large baby boom generation that is living longer, relying on the most expensive and inefficient healthcare system in the world, combined with a tax code that fails to generate sufficient revenue to pay for what we have promised our citizens. Put these factors together, and you are left with a nation in an unsustainable position, forced to borrow from its future to fund its present.</p>
        <p>How does the Foundation make progress on such an important and complex problem? We bring people and ideas together. We are a convener, a consensus builder, a solutions finder, and a trusted resource for policymakers and citizens. We educate and engage millions of Americans to increase awareness and accelerate action on our growing national debt, through a deep portfolio of activities, partnerships and grants. Our work includes timely research and analysis, innovative and multi-faceted campaigns, and coalition-building to advance policy solutions and gather diverse voices from across the political spectrum and across America.</p>
        <p>Further, recognizing that healthcare performance plays an essential role in defining America&rsquo;s fiscal outlook and future economy, our Foundation has built a dedicated effort focused on improving the delivery of care. In 2014, we launched the Peterson Center on Healthcare, which is working to transform U.S. healthcare into a high-performance system by finding, validating and accelerating the adoption of proven solutions that improve quality and lower the cost of care.</p>
        <p>Addressing our nation&rsquo;s fiscal outlook has never been more urgent. At $22 trillion, the national debt is already dangerously high. This year our federal budget deficit is projected to add nearly $1 trillion more to the debt. Worse yet, we have just entered an era in which, absent policy changes, our daunting demographics will grow the deficit every single year as far as the eye can see. This is the definition of unsustainable.</p>
        <p>And the result of this irresponsible path can easily be seen in our nation&rsquo;s fastest growing government &ldquo;program&rdquo;: interest costs. Over the next ten years, we will spend a staggering $7 trillion in interest costs. Next year we will spend more on interest than we do our kids. A few years after that, interest costs will exceed what we spend on national defense. To maintain our strength as a nation, we simply can&rsquo;t spend more on our past than on our future.</p>
        <p>Why does it matter? Because our high and rising debt does real damage to our future, reducing capital for public and private investment, endangering our flexibility to deal with crises or emergencies, weakening the safety net, and diminishing economic opportunities for Americans. Put simply, our fiscal outlook has negative ramifications for Americans from every state, generation and political party.</p>
        <p>Unfortunately, hyper-partisanship in Washington continues to stand in the way of meaningful progress on these non-partisan realities. In fact, recent tax and spending legislation have added trillions more in red ink, only making matters worse. The sooner we address these challenges, the easier the task will be.</p>
        <p>Sadly, my father passed away in 2018 at the age of 91. I will be forever grateful for having him as a role model, and for the opportunity to work alongside him at the Foundation over the past decade. His spirit, energy and tenacity continue to inspire us every day.</p>
        <p>My father and I built an organization that is poised for growth, and the first ten years of the Peterson Foundation are just the beginning. To meet the magnitude of these critical challenges, our resources, commitment and impact will expand significantly in the years ahead, as we strive for a more secure and prosperous economy with widely-shared opportunity. Our nation&rsquo;s future is at stake, and our kids and grandkids deserve better.</p>
        <p>With a dedicated staff and committed partners, we are just getting started in our mission to restore the American dream that my father was so fortunate to have lived. </p>
        <div className="contain--small center-margin">
          <Image
            src={Signature}
            alt="" />
          <div>
            <div className="bold">Michael A. Peterson</div>
            <div>Chairman and Chief Executive Officer</div>
          </div>
        </div>
      </ReadMore>
    </div>
  </>
);